import { Empty } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router";
import { END_POINTS } from "../../common/constants";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import UserForm from "./components/user-form";
import { useTranslation } from "react-i18next";

const UserEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    resource: user,
    fetching,
    failed,
  } = useFetchData<IUserForm>({
    url: `${END_POINTS.USER.DETAIL}${id}`,
    method: "get",
  });

  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("User.Edit User"));

    return () => {
      header.resetTitle();
    };
  }, []);

  if (fetching) return <Loading />;
  if (failed) return <Failed />;
  if (!user) return <Empty />;
  return <UserForm init={user} />;
};

export default UserEdit;
