import {
  IndexRouteProps,
  LayoutRouteProps,
  Navigate,
  PathRouteProps,
  Route,
  Routes,
} from "react-router-dom";
import Login from "../features/login";
import publicRoutes from "./publicRoutes";

const routes: (PathRouteProps | LayoutRouteProps | IndexRouteProps)[] = [
  { path: "/", element: <Login /> },
];

const AuthRoutes = () => {
  return (
    <Routes>
      {routes.map((route, idx) => (
        <Route
          key={idx}
          {...route}
        />
      ))}
      {publicRoutes.map((route, idx) => (
        <Route
          key={idx}
          {...route}
        />
      ))}
      <Route
        path="*"
        element={
          <Navigate
            replace
            to="/"
          />
        }
      />
    </Routes>
  );
};

export default AuthRoutes;
