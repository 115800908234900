import { SearchOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import AppButton from ".";

const SearchButton: FC<PropsWithChildren<ButtonProps>> = props => {
  return (
    <AppButton
      className="bg-[#0000FF] text-white border-[#0000FF] hover:bg-[#061178] hover:text-white hover:border-[#061178]"
      {...props}
    >
      <SearchOutlined />
      {props.children}
    </AppButton>
  );
};

export default SearchButton;
