import { Divider, PageHeader as Header } from "antd";
import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts";

const DEFAULT_TITLE = "Credinse Pay";

const PageHeader = () => {
  const store = useContext(AppContext);
  const { headerTitle, headerExtra } = store;

  useEffect(() => {
    document.title = headerTitle || DEFAULT_TITLE;
  }, [headerTitle]);
  return (
    <div className="bg-white sticky top-0 z-10 pt-6">
      <Header
        backIcon={null}
        ghost={false}
        title={headerTitle}
        extra={headerExtra}
        style={{ padding: 0 }}
      />
      <Divider className="mt-4 mb-12" />
    </div>
  );
};

export default PageHeader;
