import { Navigate, PathRouteProps, Route, Routes } from "react-router-dom";
import ChangePassword from "../features/change-password";
import Currency from "../features/currency";
import CurrencyRate from "../features/currency-rate";
import CreateCurrency from "../features/currency-rate/create";
import CurrencyDetail from "../features/currency-rate/detail";
import CurrencyList from "../features/currency-rate/list";
import Dashboard from "../features/dashboard";
import AppLayout from "../features/layout";
import Service from "../features/service";
import CreateService from "../features/service/create";
import ServiceDetail from "../features/service/detail";
import ServiceList from "../features/service/list";
import Transaction from "../features/transaction";
import TransactionList from "../features/transaction/list";
import TransactionDetail from "../features/transaction/view";
import User from "../features/user";
import UserCreate from "../features/user/create";
import UserEdit from "../features/user/edit";
import UserList from "../features/user/list";
import UserSettings from "../features/user/user-settings";
import publicRoutes from "./publicRoutes";

interface IAppRoute extends PathRouteProps {
  childRoutes?: IAppRoute[];
}

const routes: IAppRoute[] = [
  { path: "/", element: <Dashboard /> },
  {
    path: "/user",
    element: <User />,
    childRoutes: [
      {
        path: "",
        element: <UserList />,
      },
      {
        path: "create",
        element: <UserCreate />,
      },
      {
        path: "edit/:id",
        element: <UserEdit />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/service",
    element: <Service />,
    childRoutes: [
      {
        path: "",
        element: <ServiceList />,
      },
      {
        path: "create",
        element: <CreateService />,
      },
      {
        path: "edit/:id",
        element: <ServiceDetail />,
      },
    ],
  },
  {
    path: "/currency",
    element: <Currency />,
  },
  {
    path: "/currency-rate",
    element: <CurrencyRate />,
    childRoutes: [
      {
        path: "",
        element: <CurrencyList />,
      },
      {
        path: "create",
        element: <CreateCurrency />,
      },
      {
        path: "edit/:id",
        element: <CurrencyDetail />,
      },
    ],
  },
  {
    path: "/transaction",
    element: <Transaction />,
    childRoutes: [
      {
        path: "",
        element: <TransactionList />,
      },
      {
        path: "view/:id",
        element: <TransactionDetail />,
      },
    ],
  },
  {
    path: "/user-settings",
    element: <UserSettings />,
  },
];

const renderRoute = (route: IAppRoute, index: number) => {
  return (
    <Route
      {...route}
      key={index}
    >
      {route.childRoutes &&
        route.childRoutes.length > 0 &&
        route.childRoutes.map((r, idx) => renderRoute(r, idx))}
    </Route>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          key={idx}
          {...route}
        />
      ))}
      <Route
        path="/"
        element={<AppLayout />}
      >
        {routes.map((route, idx) => renderRoute(route, idx))}
        <Route
          path="*"
          element={
            <Navigate
              replace
              to="/"
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
