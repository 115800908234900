import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import AppButton from ".";

const CreateButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { t } = useTranslation();
  return (
    <AppButton
      className="bg-[#0000FF] text-white border-[#0000FF] hover:bg-[#061178] hover:text-white hover:border-[#061178]"
      {...props}
    >
      <PlusCircleOutlined /> {props.children || t("Create")}
    </AppButton>
  );
};

export default CreateButton;
