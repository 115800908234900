import { useContext } from "react";
import { DispatchContext, IActionType } from "../contexts";

const useLoading = () => {
  const dispatch = useContext(DispatchContext);
  return {
    show: () => {
      dispatch({
        type: IActionType.SET_LOADING,
        payload: {
          loading: true,
        },
      });
    },
    hide: () => {
      dispatch({
        type: IActionType.SET_LOADING,
        payload: {
          loading: false,
        },
      });
    },
  };
};

export default useLoading;
