import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../apiClient";
import {
  DATE_FORMAT,
  DATE_QUERY_FORMAT,
  END_POINTS,
  PAGE_SIZE_OPTIONS,
} from "../../common/constants";
import alertService from "../../common/services/alertService";
import AppButton from "../../components/buttons";
import ClearButton from "../../components/buttons/clear";
import SearchButton from "../../components/buttons/search";
import ViewButton from "../../components/buttons/view";
import SearchPanel from "../../components/search-panel";
import Seperate from "../../components/seperate";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import useList from "../../hooks/use-list";
import useLoading from "../../hooks/use-loading";

const VIEW_TRANSACTION_PAGE = "/transaction/view";
const DATETIME_EXPORT_FORMAT = "yyyy-MM-DD HH:mm";

interface ExportInputs {
  fromDate: string;
  toDate: string;
}

const { Item, useForm } = Form;

const defaultFileName = "export.txt";

interface ITransactionQuery {
  id_guid?: number;
  serviceIds: number[];
  nid_Nipt?: string;
  createdDateTo?: string;
  createdDateFrom?: string;
  isCreatedDateBetweenUsed: boolean;
  createdByUser: number;
  cardNumber: string;
  paymentStatus: string;
  amountFrom: number;
  amountTo: number;
}

const TransactionList = () => {
  const navigate = useNavigate();
  const { selectedKey, setSelectedKey, filters, setFilters } =
    useList<ITransactionQuery>();

  const loading = useLoading();

  const {
    resource: pagination,
    fetching,
    failed,
  } = useFetchData<IPaginationResponse<ITransaction>>({
    url: END_POINTS.TRANSACTIONS.LIST,
    method: "post",
    data: filters,
  });
  const { resource: hasAccess } = useFetchData<boolean>({
    url: END_POINTS.TRANSACTIONS.HAS_ACCESS,
    method: "get",
  });
  const { t } = useTranslation();

  const [panelFilters, setPanelFilter] = useState(filters);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const exportDateValidations: IRules = {
    fromDate: [{ required: true, message: t("Please enter from date") }],
    toDate: [{ required: true, message: t("Please enter to date") }],
  };

  const header = useHeader();

  const { resource: services, fetching: fetchingServices } = useFetchData<
    IService[]
  >({
    url: END_POINTS.SERVICE.LIST,
    method: "get",
  });

  const serviceOptions: IOption[] = services
    ? services.map(c => ({ label: c.title, value: c.id }))
    : [];
  const paymentStatusOptions: IOption[] = [
    { label: "CREATED", value: "CREATED" },
    { label: "REDIRECTED_TO_PAY", value: "REDIRECTED_TO_PAY" },
    { label: "PAID", value: "PAID" },
    { label: "FAILED", value: "FAILED" },
  ];
  useEffect(() => {
    header.setTitle(t("Transaction.Transaction"));

    return () => {
      header.resetTitle();
    };
  }, [t]);

  const { resource: users, fetching: fetchingUsers } = useFetchData<IUser[]>({
    url: END_POINTS.USER.LIST,
    method: "get",
  });

  const userOptions: IOption[] = users
    ? users.map(c => ({ label: c.fullname, value: c.user_Id }))
    : [];

  const onChangeStartDate: DatePickerProps["onChange"] = (
    date: Moment | null,
    dateString: string
  ) => {
    const filterStartDate = date
      ? moment(date).format(DATE_QUERY_FORMAT)
      : undefined;
    const isCreatedDateBetweenUsed =
      !!filterStartDate && !!panelFilters?.createdDateTo;
    setPanelFilter((f: any) => ({
      ...f,
      createdDateFrom: filterStartDate,
      isCreatedDateBetweenUsed,
    }));
  };

  const onChangeEndDate: DatePickerProps["onChange"] = (
    date: Moment | null,
    dateString: string
  ) => {
    const filterEndDate = date
      ? moment(date).format(DATE_QUERY_FORMAT)
      : undefined;
    const isCreatedDateBetweenUsed =
      !!filterEndDate && !!panelFilters?.createdDateFrom;
    setPanelFilter((f: any) => ({
      ...f,
      createdDateTo: filterEndDate,
      isCreatedDateBetweenUsed,
    }));
  };

  const performSearch = () => {
    setFilters(panelFilters);
  };

  const clearFilters = () => {
    const newFilters = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber,
    };
    setPanelFilter(newFilters);
    setFilters(newFilters);
  };

  useEffect(() => {
    const extra = (
      <Space size="small">
        {hasAccess && (
          <AppButton onClick={showModal}>
            <FileOutlined /> {t("Export")}
          </AppButton>
        )}
        <ViewButton
          disabled={!selectedKey}
          onClick={() => {
            selectedKey && navigate(`${VIEW_TRANSACTION_PAGE}/${selectedKey}`);
          }}
        />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [selectedKey, hasAccess]);

  const COLUMNS: ColumnsType<ITransaction> = [
    {
      title: t("Transaction.GUID"),
      dataIndex: "idGuid",
      key: "idGuid",
      render: value => <span className="text-[xx-small]">{value}</span>,
    },
    {
      title: t("Transaction.VAT/PO"),
      dataIndex: "nidNipt",
      key: "nidNipt",
    },
    {
      title: t("Transaction.Card Number"),
      dataIndex: "cardNumber",
      key: "cardNumber",
    },
    {
      title: t("Transaction.Payment Status"),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: t("Transaction.Result Code"),
      dataIndex: "resultCode",
      key: "resultCode",
      width: "1%",
      render: value => {
        if (!value) return null;
        const bg_color = value == "00" ? "#43cc61" : "#cc4382";
        return (
          <div className="flex justify-center items-center">
            <span
              className="border-[#463b41] border-[1px] border-solid p-1 text-white rounded"
              style={{ backgroundColor: bg_color }}
            >
              {value}
            </span>
          </div>
        );
      },
    },
    {
      title: t("Transaction.Service"),
      dataIndex: "serviceName",
      key: "serviceName",
      render: value => <span className="text-[xx-small]">{value}</span>,
    },
    {
      title: t("Transaction.Total amount"),
      dataIndex: "vleraSherbimitNeLeke",
      key: "vleraSherbimitNeLeke",
    },
    {
      title: t("Transaction.Currency Code"),
      dataIndex: "currencyCode",
      key: "currencyCode",
      width: "1%",
      render: value => <div className="text-center">{value}</div>,
    },
    {
      title: t("Transaction.Created Date"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: value => {
        return moment(value).format(DATE_FORMAT);
      },
    },
    {
      title: t("Transaction.Created By"),
      dataIndex: "createdByUser",
      key: "createdByUser",
    },
  ];

  const tableSource = pagination?.items.map(r => ({ ...r, key: r.id }));

  const [exportForm] = useForm<ExportInputs>();

  const submitExportForm = async (values: ExportInputs) => {
    if (Object.values(values).some(val => !val)) return;

    const date_from = values.fromDate.replaceAll("T", " ");
    const date_to = values.toDate.replaceAll("T", " ");

    try {
      loading.show();
      const response = await api.post(
        END_POINTS.TRANSACTIONS.GET_TRANSACTION_EXPORT,
        { date_from, date_to },
        {
          responseType: "blob",
        }
      );
      const fileName =
        response.headers["content-disposition"]
          ?.split("; ")[1]
          ?.split("filename=")[1] || defaultFileName;
      const href = URL.createObjectURL(response.data as Blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName as string);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error: any) {
      let statusCode = error.response.status;

      if (statusCode === 400) {
        let responseObj = await error.response.data.text();
        const err = JSON.parse(responseObj);
        alertService.error(err.title);
        return;
      }

      alertService.error(error.message);
    } finally {
      loading.hide();
    }
  };

  const nowMoment = moment().format("yyyy-MM-DDTHH:mm").toString();

  return (
    <>
      {hasAccess && (
        <Modal
          title={t("Export transaction")}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          className="min-w-fit"
        >
          <Form
            form={exportForm}
            labelCol={{ span: 8 }}
            labelAlign="left"
            onFinish={submitExportForm}
            initialValues={{
              fromDate: nowMoment,
              toDate: nowMoment,
            }}
          >
            <Row gutter={{ xs: 0, sm: 24 }}>
              <Col
                xs={24}
                sm={12}
              >
                <Item
                  name="fromDate"
                  label={t("From Date")}
                  required
                  rules={exportDateValidations.fromDate}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    className="w-full"
                    type="datetime-local"
                  />
                </Item>
              </Col>
              <Col
                xs={24}
                sm={12}
              >
                <Item
                  name="toDate"
                  label={t("To Date")}
                  required
                  rules={exportDateValidations.fromDate}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    className="w-full"
                    type="datetime-local"
                  />
                </Item>
              </Col>
            </Row>

            <div className="flex justify-center">
              <AppButton
                htmlType="submit"
                className="bg-[#0000FF] text-white border-[#0000FF] hover:bg-[#061178] hover:text-white hover:border-[#061178]"
              >
                <DownloadOutlined /> {t("Download export")}
              </AppButton>
            </div>
          </Form>
        </Modal>
      )}
      <SearchPanel>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onKeyDown={e => {
            if (e.key === "Enter") performSearch();
          }}
          labelWrap
        >
          <div className="lg:flex lg:gap-[4%]">
            <div className="lg:flex-1">
              <Item
                label={t("Transaction.Payment Status")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  className="w-full"
                  placeholder={t("Transaction.Select Payment Status")}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={paymentStatusOptions}
                  value={panelFilters.paymentStatus}
                  onChange={value => {
                    setPanelFilter((f: any) => ({
                      ...f,
                      paymentStatus: value || undefined,
                    }));
                  }}
                />
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Transaction.NID/NUIS")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="flex gap-2">
                  <Input
                    allowClear
                    placeholder={t("Transaction.Search by NID/NUIS") as string}
                    value={panelFilters.nid_Nipt}
                    onChange={e =>
                      setPanelFilter((f: any) => ({
                        ...f,
                        nid_Nipt: e.target.value,
                      }))
                    }
                  />
                </div>
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Transaction.Card Number")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="flex gap-2">
                  <Input
                    allowClear
                    placeholder={
                      t("Transaction.Search by Card Number") as string
                    }
                    value={panelFilters.cardNumber}
                    onChange={e =>
                      setPanelFilter((f: any) => ({
                        ...f,
                        cardNumber: e.target.value,
                      }))
                    }
                  />
                </div>
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Service.Service")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  className="w-full"
                  mode="multiple"
                  placeholder={t("Transaction.Select service")}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={fetchingServices}
                  options={serviceOptions}
                  value={panelFilters.serviceIds}
                  onChange={value => {
                    setPanelFilter((f: any) => ({
                      ...f,
                      serviceIds: value || undefined,
                    }));
                  }}
                />
              </Item>
            </div>
          </div>

          <div className="lg:flex lg:gap-[4%]">
            <div className="lg:flex-1">
              <Item
                label={t("From Date")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  value={
                    panelFilters?.createdDateFrom
                      ? moment(panelFilters.createdDateFrom)
                      : null
                  }
                  onChange={onChangeStartDate}
                  className="w-full"
                  placeholder={t("Select date") as string}
                />
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("To Date")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  value={
                    panelFilters?.createdDateTo
                      ? moment(panelFilters.createdDateTo)
                      : null
                  }
                  onChange={onChangeEndDate}
                  className="w-full"
                  placeholder={t("Select date") as string}
                />
              </Item>
            </div>

            <div className="lg:flex-1">
              <Item
                label={t("Transaction.Amount From")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="flex gap-2">
                  <InputNumber
                    className="w-full"
                    value={panelFilters.amountFrom}
                    min={0}
                    onChange={e =>
                      setPanelFilter((f: any) => ({
                        ...f,
                        amountFrom: e,
                      }))
                    }
                  />
                </div>
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Transaction.Amount To")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="flex gap-2">
                  <InputNumber
                    className="w-full"
                    min={0}
                    value={panelFilters.amountTo}
                    onChange={e =>
                      setPanelFilter((f: any) => ({
                        ...f,
                        amountTo: e,
                      }))
                    }
                  />
                </div>
              </Item>
            </div>
          </div>

          <div className="lg:flex lg:gap-[4%]">
            <div className="lg:flex-1">
              {hasAccess && (
                <Item
                  label={t("User.User")}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    className="w-full"
                    placeholder={t("Transaction.Select user")}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    loading={fetchingUsers}
                    options={userOptions}
                    value={panelFilters.createdByUser}
                    onChange={value => {
                      setPanelFilter((f: any) => ({
                        ...f,
                        createdByUser: value || undefined,
                      }));
                    }}
                  />
                </Item>
              )}
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Transaction.GUID")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="flex gap-2">
                  <Input
                    allowClear
                    placeholder={t("Transaction.Search by GUID") as string}
                    value={panelFilters.id_guid}
                    onChange={e =>
                      setPanelFilter((f: any) => ({
                        ...f,
                        id_guid: e.target.value,
                      }))
                    }
                  />
                </div>
              </Item>
            </div>
            <div className="lg:flex-1">
              <Item
                label={t("Transaction.Is Guid base64")}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <div className="flex gap-2">
                  <Checkbox
                    checked={panelFilters.is_id_guid_base64}
                    onChange={e => {
                      setPanelFilter((f: any) => ({
                        ...f,
                        is_id_guid_base64: !!e.target.checked,
                      }));
                    }}
                  />
                </div>
              </Item>
            </div>
            <div className="lg:flex-1"></div>
          </div>
        </Form>
        <Seperate />
        <div className="flex gap-2 justify-end mt-6">
          <ClearButton onClick={clearFilters}>{t("Clear filters")}</ClearButton>
          <SearchButton onClick={performSearch}>{t("Submit")}</SearchButton>
        </div>
      </SearchPanel>
      <br />
      <Table
        className="transaction-table"
        bordered
        rowSelection={{
          type: "radio",
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: ITransaction[]
          ) => {
            setSelectedKey(selectedRowKeys[0] as number);
          },
        }}
        loading={fetching}
        columns={COLUMNS}
        dataSource={tableSource}
        pagination={{
          locale: {
            items_per_page: `/ ${t("Page")}`,
          },
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          pageSize: filters?.pageSize,
          current: filters?.pageNumber,
          total: pagination?.totalCount,
          onChange: (page: number, pageSize: number) => {
            setFilters((filter: any) => ({
              ...filter,
              pageNumber: page,
              pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default TransactionList;
