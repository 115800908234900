import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useHeader from "../../hooks/use-header";

const Dashboard = () => {
  const { t } = useTranslation();
  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("Dashboard"));

    return () => {
      header.resetTitle();
    };
  }, [t, header]);

  return <div>{t("Dashboard")}</div>;
};

export default Dashboard;
