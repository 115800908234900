import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useHeader from "../../hooks/use-header";
import ServiceForm from "./components/service-form";

const CreateService = () => {
  const header = useHeader();
  const { t } = useTranslation();

  useEffect(() => {
    header.setTitle(t("Create Service"));

    return () => {
      header.resetTitle();
    };
  }, [t, header]);

  return <ServiceForm />;
};

export default CreateService;
