import axios, { AxiosRequestConfig } from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../common/constants";
import loginApi from "../features/login/login.api";
import { getToken, logOut } from "../utils/helper";

const { REACT_APP_BACKEND_URL } = process.env;

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${REACT_APP_BACKEND_URL}/api`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
};

const api = axios.create(axiosConfig);

api.interceptors.request.use(
  config => {
    const accessToken = getToken(ACCESS_TOKEN);
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest.sent) {
      originalRequest.sent = true;
      originalRequest._retry = true;
      try {
        const rT = getToken(REFRESH_TOKEN);
        if (!rT) throw new Error();
        const res = await loginApi.renewToken(rT);
        const { token, refreshToken } = res.data;

        originalRequest.headers = { ...originalRequest.headers };
        originalRequest.headers["Authorization"] = `Bearer ${token}`;

        axios.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem(ACCESS_TOKEN, token);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        return axios(originalRequest);
      } catch (error) {
        logOut();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
