import { CloseOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import AppButton from ".";

const ClearButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { t } = useTranslation();

  return (
    <AppButton {...props}>
      <CloseOutlined /> {props.children || t("Clear")}
    </AppButton>
  );
};

export default ClearButton;
