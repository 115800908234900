import { EditOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import AppButton from ".";

const EditButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { t } = useTranslation();

  return (
    <AppButton
      className="bg-[#fa8c16] text-white border-[#fa8c16] hover:bg-[#d46b08] hover:text-white hover:border-[#d46b08]"
      {...props}
    >
      <EditOutlined /> {props.children || t("Edit")}
    </AppButton>
  );
};

export default EditButton;
