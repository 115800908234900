import { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { USER } from "./common/constants";
import LoadingScreen from "./components/loading-screen";
import { AppContext, DispatchContext, IActionType } from "./contexts";
import AppRoutes from "./routes/appRoutes";
import AuthRoutes from "./routes/authRoutes";
import useLoading from "./hooks/use-loading";

function App() {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useContext(DispatchContext);
  const loading = useLoading();

  const store = useContext(AppContext);
  const { user } = store;

  useEffect(() => {
    const storedUser = localStorage.getItem(USER);
    loading.hide();
    setInitialized(true);
    if (!storedUser) return;
    try {
      const parsedUser = JSON.parse(storedUser);
      dispatch({
        type: IActionType.SET_USER,
        payload: { user: parsedUser },
      });
    } catch (error) {}
  }, []);

  return (
    <div className="App">
      <LoadingScreen />
      {initialized && (
        <BrowserRouter>{user ? <AppRoutes /> : <AuthRoutes />}</BrowserRouter>
      )}
    </div>
  );
}

export default App;
