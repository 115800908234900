import { Empty, Layout, Menu } from "antd";
import "antd/dist/antd.min.css";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React, { FC, PropsWithChildren, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SIDE_MENU_WIDTH } from "../../common/constants";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import PageHeader from "../../components/page-header";
import HeaderInfo from "./components/header-info";
import useSiderNavigation from "./components/siderNavigation";
import styles from "./layout.module.css";

const { Header, Content, Sider } = Layout;

const AppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const { items, fetching, failed } = useSiderNavigation();

  const renderSideMenu = () => {
    if (fetching) return <Loading />;
    if (failed) return <Failed />;
    if (!items) return <Empty />;
    if (items.length === 0) return <Empty />;
    if (items.length > 0)
      return (
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["/"]}
          selectedKeys={[path]}
          items={items as ItemType[]}
          onSelect={item => {
            navigate(item.key);
          }}
        />
      );
  };

  return (
    <div className="bg-zinc-200 h-screen flex flex-col">
      <Header className="w-full fixed px-8 z-20 top-0 left-0 flex justify-between items-center h-24">
        <div
          style={{ width: SIDE_MENU_WIDTH.EXTENDED }}
          className="pr-16"
        >
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <h3 className="text-white font-bold text-3xl">Credins ePay</h3>
          </button>
        </div>

        <HeaderInfo />
      </Header>
      <div className="h-24">{/* Header placeholder */}</div>
      <div className="flex flex-1">
        <Sider
          collapsible
          collapsedWidth={SIDE_MENU_WIDTH.COLLAPSE}
          width={SIDE_MENU_WIDTH.EXTENDED}
          className={` bg-white overflow-auto h-[calc(100vh_-_6rem_-_48px)]`}
        >
          {renderSideMenu()}
        </Sider>
        <Content className={`p-6 overflow-auto h-[calc(100vh_-_6rem)]`}>
          <div className="px-6 pb-6 h-full overflow-auto bg-white">
            <PageHeader />
            <Outlet />
          </div>
        </Content>
      </div>
    </div>
  );
};

export default AppLayout;
