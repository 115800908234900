import type { ColumnsType } from "antd/es/table";
import type { ColumnType } from "antd/es/table";
import EditableTable, {
  IEditableTableColumns,
} from "../../components/tables/editable";
import { Rule } from "antd/lib/form";

const typeOptions: IOption[] = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
];
interface IStudent extends IEntity {
  name: string;
  age: number;
  type: number;
  isActive: boolean;
}

const data: IStudent[] = [
  {
    id: 1,
    name: "Zo",
    age: 17,
    type: 1,
    isActive: true,
  },
  {
    id: 2,
    name: "Hayla",
    age: 500,
    type: 2,
    isActive: false,
  },
];

const student_columns: IEditableTableColumns<IStudent>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    dataType: "string",
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
    dataType: "number",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    dataType: "select",
  },
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    dataType: "boolean",
  },
];

const Currency = () => {
  return (
    <EditableTable<IStudent>
      columns={student_columns}
      dataSource={data}
    />
  );
};

export default Currency;
