import { notification } from "antd";

export default {
  success: (message: string) => {
    notification.success({ message: "Success", description: message });
  },
  info: (message: string) => {
    notification.info({ message: "Info", description: message });
  },
  warning: (message: string) => {
    notification.warning({ message: "Warning", description: message });
  },
  error: (message: string) => {
    notification.error({
      message: "Error",
      description: message || `An error has occurred. Please try again later`,
    });
  },
};
