import { Empty } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { END_POINTS } from "../../common/constants";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import ServiceForm from "./components/service-form";

const ServiceDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    resource: service,
    fetching,
    failed,
  } = useFetchData<IService>({
    url: `${END_POINTS.SERVICE.DETAIL}${id}`,
    method: "get",
  });

  const {
    resource: serviceUsers,
    fetching: fetchingUser,
    failed: userFailed,
  } = useFetchData<IServiceUser[]>({
    url: END_POINTS.SERVICE.GET_USERS(id as string),
    method: "get",
  });

  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("Edit Service"));

    return () => {
      header.resetTitle();
    };
  }, [t, header]);

  if (fetching || fetchingUser) return <Loading />;
  if (failed || userFailed) return <Failed />;
  if (!service) return <Empty />;
  return (
    <ServiceForm
      init={service}
      serviceUsers={serviceUsers}
    />
  );
};

export default ServiceDetail;
