import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useReducer,
  Dispatch,
} from "react";
import { APP_HEADER } from "../common/constants";

export enum IActionType {
  SET_LOADING = "SET_LOADING",
  SET_USER = "SET_USER",
  SET_HEADER_TITLE = "SET_HEADER_TITLE",
  SET_HEADER_EXTRA = "SET_HEADER_EXTRA",
}

interface IAppState {
  loading?: boolean | null;
  user?: IClient | null;
  headerTitle?: string | null;
  headerExtra?: ReactNode | null;
}

interface IAction {
  type: IActionType;
  payload: IAppState;
}

const initAppState: IAppState = {
  loading: true,
  user: null,
  headerTitle: APP_HEADER,
  headerExtra: null,
};

const appReducer = (state: IAppState, action: IAction) => {
  const { type, payload } = action;
  const newState = { ...state };
  switch (type) {
    case IActionType.SET_LOADING: {
      newState.loading = payload.loading;
      break;
    }
    case IActionType.SET_USER: {
      newState.user = payload.user;
      break;
    }
    case IActionType.SET_HEADER_TITLE:
      newState.headerTitle = payload.headerTitle;
      break;
    case IActionType.SET_HEADER_EXTRA:
      newState.headerExtra = payload.headerExtra;
      break;
    default:
      break;
  }

  console.groupCollapsed("ACTION: ", type);
  console.log("PREV: ", state);
  console.log("ACTION: ", type);
  console.log("NEXT: ", newState);
  console.groupEnd();

  return newState;
};

export const AppContext = createContext<IAppState>(initAppState);
export const DispatchContext = createContext<Dispatch<IAction>>(() => {});

const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [store, dispatch] = useReducer(appReducer, initAppState);
  return (
    <AppContext.Provider value={store}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
