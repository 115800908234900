import {
  IndexRouteProps,
  LayoutRouteProps,
  PathRouteProps,
} from "react-router-dom";
import PaySummary from "../features/pay-summary";
import RequireChangePassword from "../features/require-change-password";

const publicRoutes: (PathRouteProps | LayoutRouteProps | IndexRouteProps)[] = [
  { path: "/paysummary/:code", element: <PaySummary /> },
  { path: "/change-password", element: <RequireChangePassword /> },
];

export default publicRoutes;
