import { Empty } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router";
import { END_POINTS } from "../../common/constants";
import Failed from "../../components/indicators/failed";
import Loading from "../../components/indicators/loading";
import useFetchData from "../../hooks/use-fetch-data";
import useHeader from "../../hooks/use-header";
import TransactionForm from "./components/transaction-form";
import { useTranslation } from "react-i18next";

const TransactionDetail = () => {
  const { id } = useParams();

  const {
    resource: transaction,
    fetching,
    failed,
  } = useFetchData<ITransaction>({
    url: `${END_POINTS.TRANSACTIONS.DETAIL}${id}`,
    method: "get",
  });
  const { t } = useTranslation();

  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("Transaction.Transaction"));

    return () => {
      header.resetTitle();
    };
  }, [t]);

  if (fetching) return <Loading />;
  if (failed) return <Failed />;
  if (!transaction) return <Empty />;
  return <TransactionForm init={transaction} />;
};

export default TransactionDetail;
