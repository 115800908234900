import { Checkbox, Form, Input, Select, Space } from "antd";
import { Rule } from "antd/lib/form";
import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { END_POINTS, UserRole } from "../../../common/constants";
import BackButton from "../../../components/buttons/back";
import SaveButton from "../../../components/buttons/save";
import useHeader from "../../../hooks/use-header";
import useLoading from "../../../hooks/use-loading";
import { useTranslation } from "react-i18next";
import { mutate } from "../../../utils/helper";
import { NamePath } from "antd/lib/form/interface";
import i18n from "../../../i18n";

const { Item, useWatch } = Form;
const LIST_USER_PAGE = "/user/";

const UserForm: FC<{ init?: IUserForm }> = ({ init }) => {
  const { id } = useParams();
  const [form] = Form.useForm<IUserForm>();
  const navigate = useNavigate();
  const header = useHeader();
  const { t } = useTranslation();
  const loading = useLoading();

  const rules: IRules = {
    username: [
      {
        required: true,
        message: t("User.User Name Cannot be empty") as string,
      },
    ],
    password: [
      {
        required: !id,
        message: t("User.Password Cannot be empty") as string,
      },
    ],
    ws_password: [
      {
        required: !id,
        message: t("User.Ws_Password Cannot be empty") as string,
      },
    ],
    fullname: [
      { required: true, message: t("User.Fullname Cannot be empty") as string },
    ],
    userrole: [
      {
        required: true,
        message: t("User.User role Cannot be empty") as string,
      },
    ],
  };

  let formInit: IUserForm | undefined = undefined;

  if (init) {
    formInit = init;
  }

  const onFinish = (values: IUserForm) => {
    const submitValues = {
      ...values,
      user_id: id && parseInt(id) && init ? parseInt(id) : 0,
    };
    loading.show();
    mutate({
      config: {
        url: END_POINTS.USER.MODIFY,
        method: "post",
        data: submitValues,
      },
      onSuccess: () => {
        navigate(LIST_USER_PAGE);
      },
      onFinish: () => {
        loading.hide();
      },
    });
  };

  const roleOptions: IOption[] = [
    { label: UserRole[UserRole.Admin], value: UserRole.Admin },
    { label: UserRole[UserRole.Client], value: UserRole.Client },
  ];

  useEffect(() => {
    const extra = (
      <Space size="small">
        <BackButton
          onClick={() => {
            navigate(LIST_USER_PAGE);
          }}
        />
        <SaveButton onClick={() => form.submit()} />
      </Space>
    );
    header.setExtra(extra);

    return () => {
      header.clearExtra();
    };
  }, [id]);

  const color1 = useWatch("color1", form);
  const color2 = useWatch("color2", form);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce(
        (arr: NamePath[], field) => (
          field.errors.length && arr.push(field.name), arr
        ),
        []
      );

    form.validateFields(errorFields);
  }, [i18n.language, form]);

  return (
    <Form
      form={form}
      initialValues={formInit}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      labelWrap
    >
      <div className="lg:flex lg:gap-[16%]">
        <div className="lg:flex-1">
          <Item
            name="timestamp"
            className="hidden"
          >
            <Input />
          </Item>
          <Item
            name="username"
            label={t("User.User Name")}
            rules={rules.username}
          >
            <Input />
          </Item>
          <Item
            name="password"
            label={t("User.Password")}
            rules={rules.password}
          >
            <Input
              className="w-full"
              type="password"
              autoComplete="new-password"
            />
          </Item>
          <Item
            name="ws_password"
            label={t("User.ws_Password")}
            rules={rules.ws_password}
          >
            <Input
              className="w-full"
              type="password"
              autoComplete="new-password"
            />
          </Item>
          <Item
            name="fullname"
            label={t("User.Full Name")}
            rules={rules.fullname}
          >
            <Input />
          </Item>
          <Item
            name="user_role"
            label={t("User.Role")}
            rules={rules.userrole}
          >
            <Select
              placeholder="Select role"
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={roleOptions}
            />
          </Item>
          <Item
            name="logo"
            label={t("User.Logo")}
            rules={rules.title}
          >
            <Input />
          </Item>
        </div>
        <div className="lg:flex-1">
          <Item
            name="active_user"
            label={t("User.Active")}
            valuePropName="checked"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Checkbox />
          </Item>
          <Item
            name="change_password"
            label={t("User.Change password in the next login")}
            valuePropName="checked"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Checkbox />
          </Item>

          <Item
            label={t("User.Color 1")}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Space>
              <Item
                name="color1"
                noStyle
              >
                <Input
                  className="w-[30px] !h-[30px] border-none outline-[rgba(24,144,255,.2)] outline-[2px] outline box-shadow-none p-0 bg-transparent"
                  type="color"
                />
              </Item>
              {color1 ? (
                <p>{color1}</p>
              ) : (
                <i>{t("User.Please select color")}</i>
              )}
            </Space>
          </Item>
          <Item
            label={t("User.Color 2")}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Space>
              <Item
                name="color2"
                noStyle
              >
                <Input
                  className="w-[30px] !h-[30px] border-none outline-[rgba(24,144,255,.2)] outline-[2px] outline box-shadow-none p-0 bg-transparent"
                  type="color"
                />
              </Item>
              {color2 ? (
                <p>{color2}</p>
              ) : (
                <i>{t("User.Please select color")}</i>
              )}
            </Space>
          </Item>
        </div>
      </div>
    </Form>
  );
};

export default UserForm;
