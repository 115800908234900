import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import ChangePasswordForm from "../../components/change-password-form";

const { Title } = Typography;

const RequireChangePassword = () => {
  const { t } = useTranslation();
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="w-1/8 min-w-min max-w-xl">
        <Title>{t("Change Password")}</Title>
        <span className="text-2xl">{t("Change Password Instruction")}</span>
        <ChangePasswordForm performReLogin />
      </div>
    </div>
  );
};

export default RequireChangePassword;
