import { Form, Input } from "antd";
import { FormInstance, RuleObject } from "antd/lib/form";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  ACCESS_TOKEN,
  END_POINTS,
  PENDING_USERNAME,
  REFRESH_TOKEN,
  USER,
} from "../../common/constants";
import AppButton from "../buttons";
import { AppContext, DispatchContext, IActionType } from "../../contexts";
import useLoading from "../../hooks/use-loading";
import { mutate } from "../../utils/helper";
import useHeader from "../../hooks/use-header";
import { NamePath } from "antd/lib/form/interface";
import i18n from "../../i18n";

const { Item, useForm } = Form;
const { Password } = Input;

interface IUpdatePasswordForm {
  actualUsername: string;
  actualpass: string;
  newpass1: string;
  newpass2: string;
}

const ChangePasswordForm: FC<{ performReLogin?: boolean }> = ({
  performReLogin,
}) => {
  const [form] = useForm<IUpdatePasswordForm>();
  const { t } = useTranslation();
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showReType, setShowReType] = useState(false);
  const navigate = useNavigate();

  const header = useHeader();

  useEffect(() => {
    header.setTitle(t("Change Password"));
    return () => {
      header.resetTitle();
    };
  }, [t, header]);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      .reduce(
        (arr: NamePath[], field) => (
          field.errors.length && arr.push(field.name), arr
        ),
        []
      );

    form.validateFields(errorFields);
  }, [i18n.language, form]);

  const loading = useLoading();
  const store = useContext(AppContext);
  const dispatch = useContext(DispatchContext);
  const { user } = store;
  const pendingUser = localStorage.getItem(PENDING_USERNAME);

  const username = user?.fullName || pendingUser;
  if (!username) return <Navigate to="/" />;

  const onFinish = (values: IUpdatePasswordForm) => {
    const submitValues = { ...values, actualUsername: username };
    loading.show();
    mutate({
      config: {
        url: END_POINTS.USER.CHANGE_PASSWORD,
        method: "put",
        data: submitValues,
      },
      onSuccess: () => {
        if (!performReLogin) return;
        const loginValues: ILogin = {
          userName: username,
          password: values.newpass1,
        };
        mutate<IToken>(
          {
            config: {
              url: END_POINTS.LOGIN,
              method: "post",
              data: loginValues,
            },
            onSuccess: values => {
              const { token, refreshToken } = values;

              var decoded: IDecodedToken = jwt_decode<IDecodedToken>(token);
              const { FullName, DbName } = decoded;
              const user: IClient = {
                fullName: FullName,
                dbName: DbName,
              };

              dispatch({
                type: IActionType.SET_USER,
                payload: { user },
              });

              localStorage.setItem(USER, JSON.stringify(user));
              localStorage.setItem(ACCESS_TOKEN, token);
              localStorage.setItem(REFRESH_TOKEN, refreshToken);
              localStorage.removeItem(PENDING_USERNAME);

              navigate("/");
            },
            onFinish: () => {
              loading.hide();
            },
          },
          { hideSuccessAlert: true }
        );
      },
      onFinish: () => {
        loading.hide();
      },
    });
  };

  const rules: IRules = {
    actualpass: [
      { required: true, message: t("Please enter current password") },
    ],
    newpass1: [{ required: true, message: t("Please enter new password") }],
    newpass2: [
      (form: FormInstance) => ({
        validator: (_: RuleObject, value: string) => {
          if (!value)
            return Promise.reject(
              new Error(t("Please re-type new password") as string)
            );

          if (value !== form.getFieldValue("newpass1"))
            return Promise.reject(
              new Error(
                t("The two passwords that you entered do not match!") as string
              )
            );

          return Promise.resolve();
        },
      }),
    ],
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelWrap
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Item
        name="actualpass"
        rules={rules.actualpass}
        label={t("Current Password")}
      >
        <Password
          visibilityToggle={{
            visible: showCurrent,
            onVisibleChange: setShowCurrent,
          }}
          autoComplete="new-password"
        />
      </Item>

      <Item
        name="newpass1"
        rules={rules.newpass1}
        label={t("New Password")}
      >
        <Password
          visibilityToggle={{
            visible: showNew,
            onVisibleChange: setShowNew,
          }}
          autoComplete="new-password"
        />
      </Item>

      <Item
        name="newpass2"
        rules={rules.newpass2}
        label={t("Re-type New Password")}
      >
        <Password
          visibilityToggle={{
            visible: showReType,
            onVisibleChange: setShowReType,
          }}
          autoComplete="new-password"
        />
      </Item>

      <AppButton
        htmlType="submit"
        className="w-full bg-[#0000FF] text-white border-[#0000FF] hover:bg-[#061178] hover:text-white hover:border-[#061178]"
      >
        {t("Save")}
      </AppButton>
    </Form>
  );
};

export default ChangePasswordForm;
