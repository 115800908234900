import { AxiosError, AxiosRequestConfig } from "axios";
import api from "../apiClient";
import { ACCESS_TOKEN, REFRESH_TOKEN, USER } from "../common/constants";
import alertService from "../common/services/alertService";

type Token = typeof ACCESS_TOKEN | typeof REFRESH_TOKEN;

export const getToken = (token: Token) => localStorage.getItem(token);

export const clearToken = (token: Token) => {
  localStorage.removeItem(token);
};

export const logOut = () => {
  localStorage.removeItem(USER);
  clearToken(ACCESS_TOKEN);
  clearToken(REFRESH_TOKEN);
  window.location.href = "/";
};

interface IMutation<T> {
  config: AxiosRequestConfig;
  onSuccess?: (data: T) => void;
  onFailed?: () => void;
  onFinish?: () => void;
}

interface IMutateOptions {
  hideSuccessAlert?: boolean;
  hideErrorAlert?: boolean;
}

export function mutate<T>(params: IMutation<T>, options?: IMutateOptions) {
  const { config, onSuccess, onFailed, onFinish } = params;
  const mutate = async () => {
    try {
      const res = await api.request<T>(config);
      onSuccess?.(res.data);
      if (options?.hideSuccessAlert) return;
      alertService.success("Success");
    } catch (error) {
      onFailed?.();
      if (options?.hideErrorAlert) return;
      const err = error as AxiosError<IError>;
      if (err.response?.data.errors) {
        const errorMessages: string[] = Object.values(
          err.response?.data.errors
        ).reduce(
          (messages, errorMsgs) => [...messages, ...errorMsgs.filter(m => m)],
          []
        );
        alertService.error(errorMessages[0]);
        return;
      }
      alertService.error(err.response?.data.title || err.message);
    } finally {
      onFinish?.();
    }
  };
  return mutate();
}

export const convertBooleanEnv = (value?: string) => {
  if (value && value === "true") return true;
  return false;
};
