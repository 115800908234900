import { Button, ButtonProps } from "antd";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { green } from "@ant-design/colors";
import { SaveOutlined } from "@ant-design/icons";
import AppButton from ".";

const SaveButton: FC<PropsWithChildren<ButtonProps>> = props => {
  const { t } = useTranslation();

  return (
    <AppButton
      className="bg-[#52c41a] text-white border-[#52c41a] hover:bg-[#389e0d] hover:text-white hover:border-[#389e0d]"
      {...props}
    >
      <SaveOutlined />
      {props.children || t("Save")}
    </AppButton>
  );
};

export default SaveButton;
