import { FC } from "react";

const Item: FC<{ title: string; value?: string | number | null }> = ({
  title,
  value,
}) => (
  <div className="flex gap-3">
    <span className="font-normal xl:text-xl lg:text-lg sm:text-base text-xs text-start">{title}</span>
    <span className="xl:text-xl lg:text-lg sm:text-base text-xs flex flex-1 justify-end text-end">{value}</span>
  </div>
);

export default Item;
