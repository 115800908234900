import { Switch, SwitchProps } from "antd";
import { useTranslation } from "react-i18next";
import { LANGUAGE, languages } from "../../common/constants";

const LanguageToggle = (props: SwitchProps) => {
  const { i18n } = useTranslation();
  const onChangeLanguage = (
    checked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const selectedLanguage = checked ? languages.al : languages.en;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem(LANGUAGE, selectedLanguage);
  };
  return (
    <Switch
      {...props}
      checkedChildren={languages.al.toUpperCase()}
      unCheckedChildren={languages.en.toUpperCase()}
      checked={i18n.language === languages.al}
      onChange={onChangeLanguage}
    />
  );
};

export default LanguageToggle;
