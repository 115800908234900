import { FC } from "react";

const Header: FC<{ title: string; value?: string | number | null }> = ({
  title,
  value,
}) => (
  <div className="flex gap-3">
    <span className="font-medium xl:text-2xl lg:text-xl sm:text-lg text-sm text-start">{title}</span>
    <span className="font-medium xl:text-2xl lg:text-xl sm:text-lg text-sm flex flex-1 justify-end text-end">{value}</span>
  </div>
);

export default Header;
